<template>
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-https"/>
          Mein eigenes Passwort ändern
        </CCardHeader>

        <CCardBody>
          <ValidationObserver v-slot="{ invalid }">
            <CForm @submit.prevent="changePassword(user.userid, plainPassword)">

              <MitarbeiterPassword v-model="plainPassword" />
              <FormError v-if="formError" :errormessage="formError"/>

              <CRow>
                <CCol col="12" class="text-right">
                  <CButton color="primary" class="px-4" :disabled="invalid" type="submit">Passwort Ändern</CButton>
                </CCol>
              </CRow>

            </CForm>
          </ValidationObserver>
        </CCardBody>

      </CCard>
    </CCol>

  </CRow>
</template>

<script>
import Vue from 'vue'
import FormError from '@/components/FormError'
import MitarbeiterPassword from '@/components/FormElements/Password'

export default {
  name: 'BenutzerPassword',
  components: {
    MitarbeiterPassword,
    FormError
  },
  data () {
    return {
      plainPassword: '',
      formError: null
    }
  },
  computed: {
    user () {
      return this.$store.getters['security/user']
    }
  },
  methods: {
    changePassword (userid, password) {
      Vue.axios.patch('/mitarbeiter/password/change', {
        plainPassword: password
      }).then(() => {
        this.$snotify.success('Das Passwort wurde geändert.', {
          position: 'rightTop',
          timeout: 4000
        })
        this.formError = null
        this.plainPassword = ''
        this.$router.push({ path: '/protokoll' })
      }).catch((error) => {
        this.formError = error.response.data
      })
    }
  }
}
</script>
